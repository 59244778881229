<template>
  <div style="position: relative">
    <div class="background-color-body w-100 p-5">
        <fieldset>
            <legend class="w-auto float-none">Table (like the vaadin Grid Pro component) -> double click to modify a cell, press enter to validate</legend>
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr class="d-flex">
            <th style="flex-basis: 20%">Column 1</th>
            <th style="flex-basis: 20%">Column 2</th>
            <th style="flex-basis: 20%">Column 3</th>
            <th style="flex-basis: 20%">Column 4</th>
            <th style="flex-basis: 20%">Column 5</th>
          </tr>
        </thead>
        <tbody>
          <tr class="d-flex" v-for="(row, index) in rows" :key="index">
            <td
              style="flex-basis: 20%"
              v-for="(td, indexTd) in row"
              :key="indexTd"
            >
              <div
                @dblclick="
                  () => {
                    td.edition = !td.edition;
                  }
                "
                @keypress.enter="
                  () => {
                    td.edition = !td.edition;
                  }
                "
              >
                <span v-if="!td.edition"> {{ td.text }}</span>
                <span v-else>
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    v-model="td.text"
                  />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
        </fieldset>
        <fieldset class="mb-5">
            <legend class="w-auto float-none">Charts</legend>
            <div class="d-flex">
      <bar style="flex-basis:50%"
        
        class="mr-5"
      />
      <pie style="flex-basis:50%;width:30%"/>
            </div>
        </fieldset>
    </div>
  </div>
</template>

<style scoped>
legend{
    font-size: 200%;
    font-weight: bold;
    color: black;
}
table, td, th{
    border: 1px solid black;
}
fieldset{
    color: black;
}
</style>
<script src="./flexmaint.js"></script>